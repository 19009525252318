/**
 * Theme: Hyper - Responsive Bootstrap 4 Admin Dashboard
 * Author: Coderthemes
 * Module/App: Main Js
 */


!function ($) {
    'use strict';

    var App = function () {
        this.$body = $('body'),
        this.$window = $(window)
    };

    /**
    Resets the scroll
     */
    App.prototype._resetSidebarScroll = function() {
        // sidebar - scroll container
        $('.slimscroll-menu').slimscroll({
            height: 'auto',
            position: 'right',
            size: "8px",
            color: '#9ea5ab',
            wheelStep: 5,
            touchScrollStep: 20
        });
    },
    /** 
     * Initlizes the menu - top and sidebar
    */
    App.prototype.initMenu = function () {
        var $this = this;

        // Left menu collapse
        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $this.$body.toggleClass('sidebar-enable');
            if ($this.$window.width() > 768) {
                $this.$body.toggleClass('enlarged');
            } else {
                $this.$body.removeClass('enlarged');
            }

            // sidebar - scroll container
            $this._resetSidebarScroll();
        });

        // sidebar - main menu
        $(".side-nav").metisMenu();

        // sidebar - scroll container
        $this._resetSidebarScroll();

        // activate the menu in left side bar based on url
        $(".side-nav a").each(function () {
            var pageUrl = window.location.href.split(/[?#]/)[0];
            if (this.href == pageUrl) {
                $(this).addClass("active");
                $(this).parent().addClass("active"); // add active to li of the current link
                $(this).parent().parent().addClass("in");
                $(this).parent().parent().prev().addClass("active"); // add active class to an anchor
                $(this).parent().parent().parent().addClass("active");
                $(this).parent().parent().parent().parent().addClass("in"); // add active to li of the current link
                $(this).parent().parent().parent().parent().parent().addClass("active");
            }
        });

    },
    App.prototype.initSyntaxHighlight = function() {
        //syntax
        var entityMap = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': '&quot;',
            "'": '&#39;',
            "/": '&#x2F;'
        };
        function escapeHtml(string) {
            return String(string).replace(/[&<>"'\/]/g, function (s) {
                return entityMap[s];
            });
        }

        $(document).ready(function () {
            document.querySelectorAll("pre code").forEach(function (element, n) {
                if (element.classList.contains("escape")) {
                    var text = element.innerHTML;
                } else {
                    var text = element.innerText;
                }
                text = text.replace(/^\n/, '').trimRight();// goodbye starting whitespace
                var to_kill = Infinity;
                var lines = text.split("\n");
                for (var i = 0; i < lines.length; i++) {
                    if (!lines[i].trim()) { continue; }
                    to_kill = Math.min(lines[i].search(/\S/), to_kill);
                }
                var out = [];
                for (var i = 0; i < lines.length; i++) {
                    out.push(lines[i].replace(new RegExp("^ {" + to_kill + "}", "g"), ""));
                }
                element.innerText = out.join("\n");
            });

            $('pre code.escape').each(function (i, block) {
                hljs.highlightBlock(block);
            });
        });
    },


        /** 
         * Init the layout - with broad sidebar or compact side bar
        */
        App.prototype.initLayout = function () {
            // in case of small size, add class enlarge to have minimal menu
            if (this.$window.width() >= 768 && this.$window.width() <= 1028) {
                this.$body.addClass('enlarged');
            } else {
                if (this.$body.data('keep-enlarged') != true) {
                    this.$body.removeClass('enlarged');
                }
            }
        },

        //initilizing
        App.prototype.init = function () {
            var $this = this;
            this.initLayout();
            this.initMenu();
            this.initSyntaxHighlight();

            // on window resize, make menu flipped automatically
            $this.$window.on('resize', function(e) {
                e.preventDefault();
                $this.initLayout();
                $this._resetSidebarScroll();
            });
        },

        $.App = new App, $.App.Constructor = App


}(window.jQuery),
    //initializing main application module
    function ($) {
        "use strict";
        $.App.init();
    }(window.jQuery);
